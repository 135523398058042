<template>
  <v-container fluid>
    <v-row>
      <v-col v-if="!editCard" cols="12" sm="3">
        <p class="font-weight-medium text-lg-h6 text--secondary">Дані термінала</p>
        <div v-if="isServicePointExist">
          <h3 class="mb-1">Розташування</h3>
          <div>
            <span>Орендодавець:</span>
            <router-link
              v-if="balanceHolder"
              :to="{ name: 'PartnerCard', params: { id: currentCompanyId, partnerId: balanceHolder.id } }"
              class="font-weight-medium text-decoration-none"
            >
              {{ balanceHolder.name }}
            </router-link>
          </div>
          <div>
            <span>Назва точки:</span>
            <router-link
              :to="{
                name: 'ServicePointCard',
                params: { id: currentCompanyId, servicePointId: servicePoint.id },
              }"
              class="font-weight-medium text-decoration-none"
            >
              {{ servicePoint.name }}
            </router-link>
          </div>
          <div>
            <span>Адреса:</span>
            <router-link
              :to="{
                name: 'ServicePointCard',
                params: { id: currentCompanyId, servicePointId: servicePoint.id },
              }"
              class="font-weight-medium text-decoration-none"
            >
              {{ servicePoint.address.country }}, {{ servicePoint.address.city }}, {{ servicePoint.address.street }},
              {{ servicePoint.address.building_number }}
            </router-link>
          </div>
          <div>
            <span class="mr-1">Місце розташування:</span>
            <span class="font-weight-medium">{{ terminal.location }}</span>
          </div>
          <div>
            <v-switch
              v-model="terminal.is_searchable"
              class="mt-0"
              label="Відображається в мобільному додатку"
              color="success"
              disabled
              hide-details
            ></v-switch>
          </div>
        </div>
        <div v-else>
          <v-alert class="text-wrap" dense border="left" text type="warning" width="content">
            Не підключено до жодної торгової точки
          </v-alert>
        </div>

        <div class="mt-6">
          <h3 class="mb-1">Основні дані</h3>
          <div>
            <span class="mr-1">ID:</span>
            <span class="font-weight-medium">{{ terminal.id }}</span>
          </div>
          <div>
            <span class="mr-1">IMEI:</span>
            <span class="font-weight-medium">{{ terminal.imei }}</span>
          </div>
          <div>
            <span class="mr-1">Виробник:</span>
            <span class="font-weight-medium">{{ terminal.producer }}</span>
          </div>
          <div>
            <span class="mr-1">Модель:</span>
            <span class="font-weight-medium">{{ terminal.model }}</span>
          </div>
          <div>
            <span class="mr-1">Ємність купюроприймача:</span>
            <span class="font-weight-medium">{{ terminal.bill_acceptor_capacity }}</span>
          </div>
          <div>
            <span class="mr-1">Канал радіомодуля:</span>
            <span v-if="terminal.state" class="font-weight-medium">{{ terminal.state.info.channel }}</span>
          </div>
          <div>
            <span class="mr-1">Версія модуля радіозв'язку:</span>
            <span v-if="terminal.state" class="font-weight-medium">{{ terminal.state.info.radio }}</span>
          </div>
        </div>

        <div class="mt-6">
          <h3 class="mb-1">Зв`язок</h3>
          <div>
            <span class="mr-1">Тип зв`язку:</span>
            <span v-if="terminal.state" class="font-weight-medium">{{ terminal.state.info.network_type }}</span>
          </div>
          <div>
            <span class="mr-1">Телефон модема:</span>
            <span v-if="terminal.state" class="font-weight-medium">{{ terminal.state.info.modem_phone_num }}</span>
          </div>
          <div>
            <span class="mr-1">Рівень звязку:</span>
            <span v-if="terminal.state" class="font-weight-medium">{{ terminal.state.status.rssi }}</span>
          </div>
        </div>
      </v-col>

      <v-col v-else>
        <div v-if="isServicePointExist">
          <p class="font-weight-medium text-lg-h6 text--secondary">Дані термінала</p>
          <h3 class="mb-1">Розташування</h3>
          <div v-if="balanceHolder">
            <span class="mr-1">Орендодавець:</span>
            <span class="font-weight-medium">{{ balanceHolder.name }}</span>
          </div>
          <div>
            <span class="mr-1">Назва точки:</span>
            <span class="font-weight-medium">{{ servicePoint.name }}</span>
          </div>
          <div>
            <span class="mr-1">Адреса:</span>
            <span class="font-weight-medium"
              >{{ servicePoint.address.country }}, {{ servicePoint.address.city }}, {{ servicePoint.address.street }},
              {{ servicePoint.address.building_number }}
            </span>
          </div>
          <div class="d-flex align-baseline custom-field">
            <span class="mr-1">Місце розташування:</span>
            <v-select class="" v-model="editedItem.location" :items="servicePoint.location" dense hide-details></v-select>
          </div>
          <div>
            <v-switch
              v-model="editedItem.is_searchable"
              class="mt-1"
              label="Відображати в мобільному додатку"
              color="success"
              hide-details
            ></v-switch>
          </div>
        </div>

        <div class="mt-6">
          <h3 class="mb-1">Основні дані</h3>
          <div>
            <span class="mr-1">ID:</span>
            <span class="font-weight-medium">{{ terminal.id }}</span>
          </div>
          <div>
            <span class="mr-1">IMEI:</span>
            <span class="font-weight-medium">{{ terminal.imei }}</span>
          </div>
          <div class="d-flex align-baseline custom-field">
            <span class="mr-1">Виробник:</span>
            <v-select v-model="editedItem.producer" :items="PRODUCERS" dense hide-details></v-select>
          </div>
          <div class="d-flex align-baseline custom-field">
            <span class="mr-1">Модель:</span>
            <v-select
              :items="terminalsModelsDictionary"
              item-value="name"
              item-text="description"
              v-model="editedItem.model"
              dense
              hide-details
            ></v-select>
          </div>
          <div class="d-flex align-baseline custom-field">
            <span class="mr-1">Ємність купюроприймача:</span>
            <v-text-field v-model.number="editedItem.bill_acceptor_capacity" class="" dense hide-details></v-text-field>
          </div>
        </div>

        <div class="mt-6">
          <h3 class="mb-1">ПЗ</h3>
          <div>
            <span class="mr-1">Версія плати:</span>
            <span v-if="terminal.state" class="font-weight-medium">{{ terminal.state.info.pcb }}</span>
          </div>
          <div>
            <span class="mr-1">Версія прошивки:</span>
            <span v-if="terminal.state" class="font-weight-medium">{{ terminal.state.info.firmware }}</span>
          </div>
          <div>
            <span class="mr-1">Версія бутлоадера:</span>
            <span v-if="terminal.state" class="font-weight-medium">{{ terminal.state.info.bootloader }}</span>
          </div>
          <div>
            <span class="mr-1">Версія каналу зв'язку:</span>
            <span v-if="terminal.state" class="font-weight-medium">{{ terminal.state.info.channel }}</span>
          </div>
          <div>
            <span class="mr-1">Версія модуля радіозв'язку:</span>
            <span v-if="terminal.state" class="font-weight-medium">{{ terminal.state.info.radio }}</span>
          </div>
        </div>

        <div class="mt-6">
          <h3 class="mb-1">Зв`язок</h3>
          <div>
            <span class="mr-1">Тип зв`язку:</span>
            <span v-if="terminal.state" class="font-weight-medium">{{ terminal.state.info.network_type }}</span>
          </div>
          <div>
            <span class="mr-1">Рівень звязку:</span>
            <span v-if="terminal.state" class="font-weight-medium">{{ terminal.state.status.rssi }}</span>
          </div>
          <div>
            <span class="mr-1">Телефон модема:</span>
            <span v-if="terminal.state" class="font-weight-medium">{{ terminal.state.info.modem_phone_num }}</span>
          </div>
          <div>
            <span class="mr-1">На рахунку модема:</span>
            <span v-if="terminal.state" class="font-weight-medium">{{ terminal.state.info.modem_balance }}</span>
          </div>
        </div>
      </v-col>

      <DevicesTable v-if="!editCard" :terminal-id="terminal.id" />
    </v-row>
  </v-container>
</template>

<script>
import { ROUTER_LINKS } from '@/const/router-links.enum'
import { TERMINAL_SCHEMA } from '@/const/apiSchemas'
import { PRODUCERS } from '@/const/producers.enum'
import { mergeObjects } from '@/helpers/mergeObjects'
import { isObjectEmpty } from '@/helpers/isObjectEmpty'
import { mapState } from 'vuex'

export default {
  name: 'TerminalDevices',
  components: {
    DevicesTable: () => import('@/components/common/DevicesTable'),
  },
  props: {
    terminal: {
      type: Object,
      required: true,
      default: () => {},
    },
    isDataSaved: {
      type: Boolean,
      required: true,
      default: false,
    },
    currentTab: {
      required: true,
      default: null,
    },
    isLoaded: {
      type: Boolean,
      required: true,
      default: false,
    },
    editCard: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    ROUTER_LINKS: ROUTER_LINKS,
    PRODUCERS: PRODUCERS,
    editedItem: { ...TERMINAL_SCHEMA },
    page: 1,
  }),

  computed: {
    ...mapState('dictionaries', ['terminalsModelsDictionary']),
    currentCompanyId() {
      return this.$route.params.id
    },
    isServicePointExist() {
      return !isObjectEmpty(this.servicePoint)
    },
    balanceHolder() {
      return this.terminal?.service_point?.balance_holder || {}
    },
    servicePoint() {
      return this.terminal?.service_point || {}
    },
  },

  watch: {
    editCard(val) {
      if (!val) {
        this.initialize()
      }
    },
    isDataSaved(val) {
      if (val) {
        this.$emit('saveData', this.editedItem)
      }
    },
  },

  mounted() {
    this.initialize()
  },

  methods: {
    async initialize() {
      this.editedItem = mergeObjects(this.editedItem, this.terminal)
    },
  },
}
</script>

<style scoped lang="scss">
.custom-field {
  max-width: 500px !important;
}
</style>
